import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";

import MisCursos from "../views/Dahsboard/MisCursos.vue";
import Curso from "../views/Dahsboard/Curso.vue";
import Leccion from "../views/Dahsboard/Leccion.vue";
import Certificados from "../views/Dahsboard/Certificados.vue";
import PerfilUsuario from "../views/Dahsboard/PerfilUsuario.vue";



//Marcas
import HomeMarcas from "../views/Pages/HomeMarcas.vue";
import Login from "../views/Pages/Login.vue";
import Inicio from "../views/Dahsboard/Inicio.vue";
import Calendario from "../views/Dahsboard/Calendario.vue";
import Modulos from "../views/Dahsboard/Modulo.vue";
import Soporte from "../views/Dahsboard/Soporte.vue";
import Examen from "../views/Dahsboard/Examen.vue";
import CertificadosPorModulo from "../views/Dahsboard/CertificadosPorModulo.vue";

const routes = [{
        path: "/",
        name: "/",
        redirect: "/inicio",
    },

    {
        path: "/plataformas",
        name: "Home Marcas",
        component: HomeMarcas,
        meta: {
            title: 'Plataformas - Aula Virtual',
            auth: false,
        },
    },

    {
        path: "/inicio",
        name: "Inicio",
        component: Inicio,
        meta: {
            title: 'Inicio - Mi Aula Virtual',
            auth: true,
        },
    },

    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: 'Inicio de Sesión',
            auth: false,
        },
    },


    {
        path: "/miscursos",
        name: "Mis Cursos",
        component: MisCursos,
        meta: {
            title: 'Mis Cursos',
            auth: false,

        },
    },
    {
        path: "/miscursos/:id_curso/modulos",
        name: "Modulos",
        component: Modulos,
    },
    {
        path: "/examen/modulo/:idmodulo",
        name: "Examen",
        component: Examen,
        meta: {
            title: 'Examen',
            auth: true,

        },
    },
    {
        path: "/calendario",
        name: "Calendario",
        component: Calendario,
        meta: {
            title: 'Calendario',
            auth: false,

        },
    },


    {
        path: "/miscursos/:idcurso",
        name: "Curso",
        component: Curso,
    },




    {
        path: '/perfil',
        name: 'PerfilUsuario',
        component: PerfilUsuario,
    },


    {
        path: "/miscursos/:idcurso/modulos/:idmodulo/leccion/:idleccion",
        name: "Leccion",
        component: Leccion,


    },

    {
        path: "/miscertificados",
        name: "Mis Certificados",
        component: Certificados,
    },

    {
        path: '/certificado-modulo/:id_curso',
        name: 'CertificadoporModulo',
        component: CertificadosPorModulo,
    },


    {
        path: "/soporte",
        name: "Soporte",
        component: Soporte,
    },



    {
        path: "/dashboard-default",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/tables",
        name: "Tables",
        component: Tables,
    },
    {
        path: "/billing",
        name: "Billing",
        component: Billing,
    },
    {
        path: "/virtual-reality",
        name: "Virtual Reality",
        component: VirtualReality,
    },
    {
        path: "/rtl-page",
        name: "RTL",
        component: RTL,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/signin",
        name: "Signin",
        component: Signin,
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
    linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('authToken');
    const user = JSON.parse(localStorage.getItem('user'));

    if (to.matched.some(record => record.meta.auth)) {
        if (!isAuthenticated) {
            next({ name: 'Login' });

        } else if (!user) {
            next({ name: 'Login' }); // Si el usuario no está definido, redirige a la página de inicio de sesión
        } else {
            next();
        }
    } else {
        next();
    }
});


export default router;