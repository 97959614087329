<script setup>
/*import { computed } from "vue";*/
import { useRoute } from "vue-router";
/*import { useStore } from "vuex";*/

import SidenavItem from "./SidenavItem.vue";
import router from "../../router";

/*const store = useStore();*/
/*const isRTL = computed(() => store.state.isRTL);*/

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
const CerrarSesion=()=>{
  localStorage.removeItem('authToken');
  localStorage.removeItem('user');
  router.push({name:'Login'})
}
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100 bg-conit pb-4 mt-4 "
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/inicio"
          :class="getRoute() === 'inicio' ? 'active' : ''"
          :navText="'Inicio'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/miscursos"
          :class="getRoute() === 'miscursos' ? 'active' : ''"
          :navText="'Mis Cursos'"
        >
          <template v-slot:icon>
            <i class="ni ni-books text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <!--<li class="nav-item">
        <sidenav-item
          to="/calendario"
          :class="getRoute() === 'Calendario' ? 'active' : ''"
          navText="Calendario"
        >
          <template v-slot:icon>
            <i class="ni ni-calendar-grid-58 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/miscertificados"
          :class="getRoute() === 'miscertificados' ? 'active' : ''"
          :navText="'Certificados'"
        >
          <template v-slot:icon>
            <i class="ni ni-badge text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/perfil"
          :class="getRoute() === 'Perfil' ? 'active' : ''"
          navText="Perfil"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/soporte"
          :class="getRoute() === 'Soporte' ? 'active' : ''"
          navText="Soporte"
        >
          <template v-slot:icon>
            <i class="ni ni-headphones text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>-->
      <li class="nav-item mt-auto text-center ">
        <div style="margin-top: 100%;">  <i class="ni ni-button-power text-white text-sm"></i>
        <a href="#" @click="CerrarSesion" class="text-white">Cerrar Sesión</a>
</div>
            </li>
    </ul>
  </div>
</template>
